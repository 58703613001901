<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>フリガナ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="nameKana"
                                  v-model="searchForm.nameKana"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="50"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  name="telephone"
                                  v-trim
                                  v-number
                                  v-maxlength
                                  maxlength="14"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-model="searchForm.number"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>誕生日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="birthDayStart"
                                      v-model="searchForm.birthDayStart"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="birthDayEnd"
                                      v-model="searchForm.birthDayEnd"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>登録日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="hasEventsRole && childList.length > 1">
                        <FormRow>
                          <template v-slot:label>参加イベント</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="eventId" v-model="searchForm.eventId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in childList" :key="item.id" :value="item.directory">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-show="isSearchFormOpened && !isClientChildOrStoreGroup">
                    <div class="row">
                      <div class="col" v-if="useOmise">
                        <FormRow>
                          <template v-slot:label>モバイル会員番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  name="omiseNumber"
                                  v-number
                                  v-model="searchForm.omiseNumber"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-show="identityVerification">
                        <FormRow>
                          <template v-slot:label>住所</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="address"
                                  v-model="searchForm.address"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-show="isSearchFormOpened && identityVerification && !isClientChildOrStoreGroup">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>マイナンバー連携日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="dateLinkedStart"
                                      v-model="searchForm.dateLinkedStart"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="dateLinkedEnd"
                                      v-model="searchForm.dateLinkedEnd"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isSearchFormOpened && isBtnMoreShown && (!useTicketFlag || !isClientChild)">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>顧客選択</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <ul class="checkboxRadio mb-10 mt-10">
                                <li v-for="item in customerTypes" :key="item.value" class="checkboxRadio-item">
                                  <label class="form-radio">
                                    <input
                                      class="form-radio-input"
                                      type="radio"
                                      name="type"
                                      :value="item.value"
                                      v-model="searchForm.type"
                                    />
                                    <span class="form-radio-label">{{ item.label }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <transition name="fade" v-if="isCpmType">
                              <div class="form-content-row">
                                <ul class="listGrid">
                                  <li v-for="item in userCpmStatus" :key="item.value" class="listGrid-item">
                                    <label class="form-check form-check-btn d-inline-block">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="isActiveCpm"
                                        :value="item.value"
                                        v-model="searchForm.isActiveCpm"
                                      />
                                      <span class="form-check-label">{{ item.label }}</span>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </transition>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item" v-if="isBtnMoreShown && (!useTicketFlag || !isClientChild)">
                    <button class="btn btn-white" type="button" @click="addSearch">{{ isSearchFormOpened ? '閉じる' : '高度な検索' }}</button>
                  </li>
                  <li class="listGrid-item pos-end">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-main" type="button" @click="search">検索</button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay :position="customPosition" color="dark">
                  <template v-slot:title>顧客累計数</template>
                  <template v-slot:num>{{ customerTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                  <template v-slot:sub>※退会は含まない</template>
                </NumDisplay>
              </template>
            </Panel>
            <Panel type="board" :padding="false" :center="true" v-if="isUseCpmParentEvent">
              <template v-slot:body>
                <NumDisplay position="start" color="dark">
                  <template v-slot:title>user ID累計数</template>
                  <template v-slot:num>{{ customerCpmTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                  <template v-slot:sub>※すべての顧客</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :checkedKey="checkedKey"
                    ref="table"
                    :count="customerCount"
                    :data-table="dataTable"
                    :labels="labels"
                    :isDisabledCheckAll="!existsOmiseNumber"
                    @select-items="handleSelectedItems"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="customerCount"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer v-if="$permission.isGmoOrOffice()">
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!customerList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
          <li class="listGrid-item" v-if="isUseCpmParentEvent && !isClientChild">
            <button class="btn btn-black" type="button" @click="uploadCsv" :disabled="isImportDisabled">CPM/CSVアップロード</button>
            <input
              type="file"
              style="display: none"
              ref="csvInput"
              accept=".csv"
              @change="onCsvFilePicked"
            />
          </li>
          <li class="listGrid-item" v-if="!isClientChild && !useLinkageNecFlag">
            <button class="btn btn-black" type="button" @click="uploadNormalCsv" :disabled="isNormalImportDisabled">CSVアップロード</button>
            <input
              type="file"
              style="display: none"
              ref="normalCsvInput"
              accept=".csv"
              @change="onNormalCsvFilePicked"
            />
          </li>
          <template v-if="useOmise && !isClientChildOrStoreGroup">
            <li class="listGrid-item">
              <button class="btn btn-main" type="button" @click="handleOpenModal(notificationModal)">プッシュ通知</button>
            </li>
            <li class="listGrid-item">
              <button class="btn btn-main" type="button" @click="handleOpenModal(couponModal)">クーポン配信</button>
            </li>
            <li class="listGrid-item">
              <button class="btn btn-main" type="button" @click="handleOpenModal(newsModal)">ニュース配信</button>
            </li>
          </template>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item" v-if="isUseCpmParentEvent && !isClientChild">
                <button class="btn btn-main" :disabled="isCpmDisabled" type="button" @click="handleShowModal">CPM ID発行</button>
              </li>
              <li class="listGrid-item" v-if="!isClientChild && !useLinkageNecFlag">
                <router-link class="btn btn-main" :to="{ name: 'EventUserRegister' }">新規登録</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
    <Modal @close="closeModal('customerCpmModal')" v-if="customerCpmModal">
      <template v-slot:headline>
        ID発行
      </template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-group">
                <div class="form-group-item">
                  <input
                    :class="{ 'form-control': true, 'is-error': errors.amount }"
                    type="tel"
                    v-number
                    name="amount"
                    v-model="form.amount"
                  />
                </div>
                <span>{{ customerCpmNote }}</span>
              </div>
            </div>
          </template>
        </FormRow>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="closeModal('customerCpmModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="submitCustomerCpm" button-text="発行する" />
          </li>
        </ul>
      </template>
    </Modal>
    <ModalSendNotification
      :buttonText="buttonText"
      :headerTitle="headerTitle"
      :modalType="modalType"
      :handleCloseModal="() => closeModal('pushModal')"
      :isModalShown.sync="pushModal"
      @handle-push-modal="handleVerifyModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
import { required, between } from 'vuelidate/lib/validators';
import * as Encoding from 'encoding-japanese';
//mixins
import flatpickr from '@/mixins/plugin/flatpickr';
import nav from '@/mixins/nav/user';
import checkall from '@/mixins/plugin/checkall';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import modal from '@/mixins/plugin/modal';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
import Modal from '@/components/Modal.vue';
import ModalSendNotification from '@/components/ModalSendNotification.vue';
//helpers
import { formatLocalString, formatDateAndTime, endline, breakLineWithComma } from '@/helpers/formatData';
import { checkFileSize, checkCsvFile } from '@/helpers/file';
//constants
import { StatusConstants } from '@/constants/status';
import { FileConstants } from '@/constants/file';

export default {
  name: 'EventUser',
  data: function() {
    return {
      pageName: '顧客管理',
      modulePath: 'customer/getCustomerList',
      searchConditions: {},
      selectedUsers: {
        allFlag: false,
        ids: [],
      },
      checkedKey: 'numberPut',
      storeModule: "customer",
      searchFields: [
        'name', 'email', 'number', 'type', 'isActiveCpm', 'telephone', 'startDate', 'endDate', 'birthDayStart', 'birthDayEnd',
        'eventId', 'omiseNumber', 'address', 'dateLinkedStart', 'dateLinkedEnd', 'nameKana'
      ],
      storedField: 'idRequestExportCustomer',
      customerCpmModal: false,
      confirmDuplicateModal: false,
      form: {},
      customerCpmNote: '件　user IDを新規で発行します。',
      isImportDisabled: false,
      isNormalImportDisabled: false,
      isCpmDisabled: false,
      isSearchFormOpened: false,
      notificationModal: StatusConstants.pushModal.notification,
      couponModal: StatusConstants.pushModal.coupon,
      newsModal: StatusConstants.pushModal.news,
      pushModal: false,
      modalType: '',
      allType: StatusConstants.customerType.all.value,
      normalType: StatusConstants.customerType.normal.value,
      cpmType: StatusConstants.customerType.cpm.value,
      omiseType: StatusConstants.customerType.omise.value,
      identityType: StatusConstants.customerType.identity.value,
      smsType: StatusConstants.customerType.sms.value,
      statusNormalTimer: null,
    };
  },
  mixins: [nav, checkall, search, download, flatpickr, modal, error],
  components: {
    FormRow,
    NumDisplay,
    TableWithCheckbox,
    PaginationLog,
    Flatpickr,
    ModalConfirmDownload,
    Modal,
    ModalSendNotification
  },
  validations() {
    const form = {
      amount: { required, between: between(1, 1000) }
    };
    return { form };
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      customerList: 'customer/customerList',
      customerCount: 'customer/customerCount',
      customerTotal: 'customer/customerTotal',
      isClientChild: 'auth/isClientChild',
      customerCpmTotal: 'customer/customerCpmTotal',
      parentEventDetail: 'event/parentEventDetail',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      isUsePointFlagParentEvent: 'event/isUsePointFlagParentEvent',
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      useTicketFlag: 'event/useTicketFlag',
      isClientChildOrStoreGroup: 'auth/isClientChildOrStoreGroup',
      useOmise: 'event/useOmise',
      identityVerification: 'event/identityVerification',
      childList: 'customer/childList',
      existsOmiseNumber: 'customer/existsOmiseNumber',
      hasEventsRole: 'auth/hasEventsRole',
      useSms: 'event/useSms',
      hasSmsParentEvent: 'event/hasSmsParentEvent',
      useLinkageNecFlag: 'event/useLinkageNecFlag',
    }),
    initialSearchConditions() {
      return  {
        isActiveCpm: this.searchForm?.isActiveCpm || [],
        type: StatusConstants.customerType.all.value,
        searchableDirectory: this.searchForm?.eventId || '',
      };
    },
    userCpmStatus() {
      return Object.values(StatusConstants.userCpmStatus).filter(e => !(this.isClientChild && e.label === StatusConstants.userCpmStatus.inactive.label));
    },
    customerTypes() {
      const newCustomerType = Object.values(StatusConstants.customerType);
      return newCustomerType.filter(e => {
        const cpmCustomerType = [this.allType, this.normalType, this.cpmType].includes(e.value);
        if (this.isClientChildOrStoreGroup) return cpmCustomerType;
        if (this.isUseCpmParentEvent) {
          if (this.useOmise && this.identityVerification) {
            if (!this.useSms) return [this.allType, this.normalType, this.cpmType, this.omiseType, this.identityType].includes(e.value);
            return e;
          }
          if (this.useOmise) {
            const omiseArray = [this.allType, this.normalType, this.cpmType, this.omiseType];
            const newArray = this.useSms ? [...omiseArray, this.smsType] : omiseArray;
            return newArray.includes(e.value);
          } else if (this.identityVerification) {
            const identityArray = [this.allType, this.normalType, this.cpmType, this.identityType];
            const newArray = this.useSms ? [...identityArray, this.smsType] : identityArray;
            return newArray.includes(e.value);
          } else if (this.useSms) {
            return [this.allType, this.normalType, this.cpmType, this.smsType].includes(e.value);
          } else {
            return cpmCustomerType;
          }
        }
        if (this.useOmise && this.identityVerification) {
          if (this.useSms) return [this.allType, this.omiseType, this.identityType, this.smsType].includes(e.value);
          return [this.allType, this.omiseType, this.identityType].includes(e.value);
        }
        if (this.useSms) {
          if (this.useOmise) {
            return [this.allType, this.omiseType, this.smsType].includes(e.value);
          } else if (this.identityVerification) {
            return [this.allType, this.identityType, this.smsType].includes(e.value);
          }
          return [this.allType, this.smsType].includes(e.value);
        }
        if (this.useOmise) return [this.allType, this.omiseType].includes(e.value);
        if (this.identityVerification) return [this.allType, this.identityType].includes(e.value);
        return e;
      })
    },
    isCpmType() {
      return [StatusConstants.customerType.cpm.value].includes(this.searchForm?.type);
    },
    customPosition() {
      return this.isUseCpmParentEvent ? 'end' : 'center';
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    importPath() {
      return `${this.userInfo?.id}_${this.subdomain}`;
    },
    customParams() {
      return {
        subdomain: this.subdomain,
        limit: 15,
        offset: 1,
      }
    },
    isBtnMoreShown() {
      return this.isUseCpmParentEvent || this.useOmise || this.identityVerification || this.useSms;
    },
    isNotificationModal() {
      return this.modalType === this.notificationModal;
    },
    isCouponModal() {
      return this.modalType === this.couponModal;
    },
    isNewsModal() {
      return this.modalType === this.newsModal;
    },
    headerTitle() {
      if (this.isNotificationModal) return 'プッシュ通知';
      return this.isCouponModal ? 'クーポン配信' : 'ニュース配信';
    },
    buttonText() {
      if (this.isNotificationModal) return '通知する';
      return '配信する';
    },
    labels() {
      return [
        ...(this.useOmise && !this.isClientChildOrStoreGroup ? [{ isCheckbox: true }] : []),
        { key: 'identityConfirmed', name: '本人確認', notShow: !this.identityVerification || this.isClientChildOrStoreGroup },
        { key: 'smsFlag', name: 'SMS', notShow: !this.useSms || this.isClientChildOrStoreGroup },
        { key: 'number', name: 'user ID', tdClass: 'pre-line' },
        { key: 'omiseNumber', name: 'モバイル会員', notShow: !this.useOmise || this.isClientChildOrStoreGroup },
        { key: 'name', name: 'お名前', tdClass: 'pre-line' },
        { key: 'email', name: this.hasSmsParentEvent ? 'メールアドレス/携帯電話番号' : 'メールアドレス', tdClass: 'pre-line' },
        { key: 'loginDate', name: '最終ログイン' },
        { key: 'balance', name: '残高' },
        { key: 'totalPoint', name: 'ポイント残高', notShow: this.isClientChild || !this.isUsePointFlagParentEvent || !this.canUseCpmParentEvent },
        { isButtonColumn: true },
      ];
    },
    dataTable() {
      return this.customerList.map(item => {
        return {
          ...item,
          isCheckboxDisabled: !item?.linkedOmise,
          identityConfirmed: { class: item?.identityConfirmed ? 'd-block w-100 aikon aikon-check': 'w-100 d-block' },
          smsFlag: { class: item?.smsFlag ? 'd-block w-100 aikon aikon-check': 'w-100 d-block' },
          omiseNumber: item?.omiseNumber || '',
          number: {
            content: item?.hasSwitchCpm ? breakLineWithComma((item?.number ? (item.number + ',') : '') + item?.numberPut) : item?.isCpm ? item?.number : item?.numberPut,
            color: (item?.hasSwitchCpm && item.isActive === 1) ? 'text-red' : !item.isCpm ? 'tox' : item.isActive === 1 ? 'text-red' : 'text-blue',
          },
          name: endline(item?.nameSei + ' ' + item?.nameMei, 21),
          email: !this.hasSmsParentEvent ? item?.email : (item?.email && item?.mobilephone ? breakLineWithComma(endline(item.email) + ',' + item?.mobilephone) : (endline(item?.email) || item?.mobilephone)),
          balance: '¥' + formatLocalString(this.useTicketFlag ? item?.balanceTicket : item?.balance),
          loginDate: formatDateAndTime(item.loginDate),
          totalPoint: formatLocalString(item.totalPoint) + 'P',
          buttonLinks: [
            {
              content: this.isClientChild ? '詳細' : '編集',
              class: 'btn-bd-main',
              routeName: this.isClientChild ? 'EventUserDetail' : 'EventUserEdit',
              params: { customerId: item.id }
            },
          ],
        };
      });
    },
  },
  watch: {
    'searchForm.type'(value) {
      if ([StatusConstants.customerType.all.value, StatusConstants.customerType.normal.value].includes(value)) {
        this.searchForm.isActiveCpm = [];
      } else if (!value) {
        this.searchForm.isActiveCpm = [];
        this.searchForm.type = StatusConstants.customerType.all.value;
      }
    },
    isUseCpmParentEvent(value) {
      if (value) {
        this.getCustomerTotal('all');
      }
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
      this.$store.dispatch('customer/getChildList', { subdomain: this.subdomain }),
      this.getCustomerTotal('normal'),
      this.getCustomerTotal('all')
    ]).finally(() => {
      this.$loading.clear(loading);
    });
    const importingId = (JSON.parse(localStorage.getItem('IMPORTING_CPM_SUBDOMAIN')) || {})[this.importPath];
    if (importingId) {
      this.isImportDisabled = true;
      this.checkImportStatus(importingId);
    }
    const importingNormalId = (JSON.parse(localStorage.getItem('IMPORTING_NORMAL_SUBDOMAIN')) || {})[this.importPath];
    if (importingNormalId) {
      this.isNormalImportDisabled = true;
      this.checkNormalImportStatus(importingNormalId);
    }
  },
  methods: {
    handleSelectedItems(items) {
      this.selectedUsers = items;
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch('customer/downloadCustomerList', {
        ...this.payloadDownloadCsv,
        searchableDirectory: this.searchForm?.eventId || '',
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    getCustomerTotal(type) {
      if (type !== 'all' || this.isUseCpmParentEvent) {
        this.$store.dispatch('customer/getCustomerTotal', { ...this.customParams, type: StatusConstants.customerType[type].value })
      }
    },
    // Upload CSV for cpm customer
    uploadCsv() {
      this.$refs.csvInput.click();
    },
    removeCsvFile() {
      this.$refs.csvInput.value = null;
    },
    setImportingSubdomain(id) {
      this.isImportDisabled = true;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_CPM_SUBDOMAIN')) || {};
      importingSubdomain[this.importPath] = id;
      localStorage.setItem('IMPORTING_CPM_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    removeImportingSubdomain() {
      this.isImportDisabled = false;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_CPM_SUBDOMAIN')) || {};
      delete importingSubdomain[this.importPath];
      localStorage.setItem('IMPORTING_CPM_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    onCsvFilePicked(event) {
      if (!this.isImportDisabled) {
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        if (checkFileSize(file) && checkCsvFile(file)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            const csvResult = e.target.result;
            const detectedEncoding = Encoding.detect(csvResult);
            if (FileConstants.ACCEPTED_CSV_ENCODEING.includes(detectedEncoding)) {
              const formData = new FormData();
              formData.append('input', file);
              const result = await this.$store.dispatch('customer/importCpmCsv', formData);
              if (result) {
                this.setImportingSubdomain(result.data.id);
                this.$message.showSuccess('importCsvStart');
                this.checkImportStatus(result.data.id);
              } else {
                this.removeImportingSubdomain();
              }
            } else {
              this.$message.showError('unacceptedCharsetCsv');
            }
          }
          reader.readAsBinaryString(file);
        }
      } else {
        this.$message.showError('importing_csv');
      }
      this.removeCsvFile();
    },
    checkImportStatus(id) {
      this.statusTimer = setTimeout(async () => {
        const result = await this.$store.dispatch('customer/getImportStatus', id);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkImportStatus(id);
              break;
            case 1:
              this.$message.showSuccess('import_csv_finished_success');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            case 2:
              if (result.count) this.$refs.pagination.resetPagination();
              this.downloadCsv(result.errorData, result.filename);
              this.$message.showSuccess('import_csv_finished_fail', true);
              this.removeImportingSubdomain();
              break;
            case 3:
              this.$message.showError('import_csv_fail');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            case 4:
              this.$message.showError('import_csv_duplicate_fail');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            case 5:
              this.$message.showError('import_csv_input_fail');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            default:
              break;
          }
        } else {
          this.removeImportingSubdomain();
        }
      }, 10000);
    },
    // Upload CSV for normal customer
    uploadNormalCsv() {
      this.$refs.normalCsvInput.click();
    },
    removeNormalCsvFile() {
      this.$refs.normalCsvInput.value = null;
    },
    setNormalImportingSubdomain(id) {
      this.isNormalImportDisabled = true;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_NORMAL_SUBDOMAIN')) || {};
      importingSubdomain[this.importPath] = id;
      localStorage.setItem('IMPORTING_NORMAL_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    removeNormalImportingSubdomain() {
      this.isNormalImportDisabled = false;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_NORMAL_SUBDOMAIN')) || {};
      delete importingSubdomain[this.importPath];
      localStorage.setItem('IMPORTING_NORMAL_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    onNormalCsvFilePicked(event) {
      if (!this.isNormalImportDisabled) {
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        if (checkFileSize(file) && checkCsvFile(file)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            const csvResult = e.target.result;
            const detectedEncoding = Encoding.detect(csvResult);
            if (FileConstants.ACCEPTED_CSV_ENCODEING.includes(detectedEncoding)) {
              const formData = new FormData();
              formData.append('input', file);
              const result = await this.$store.dispatch('customer/importNormalCsv', formData);
              if (result) {
                this.setNormalImportingSubdomain(result.data.id);
                this.$message.showSuccess('importCsvStart');
                this.checkNormalImportStatus(result.data.id);
              } else {
                this.removeNormalImportingSubdomain();
              }
            } else {
              this.$message.showError('unacceptedCharsetCsv');
            }
          }
          reader.readAsBinaryString(file);
        }
      } else {
        this.$message.showError('importing_csv');
      }
      this.removeNormalCsvFile();
    },
    checkNormalImportStatus(id) {
      this.statusNormalTimer = setTimeout(async () => {
        const result = await this.$store.dispatch('shop/getImportStatus', id);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkNormalImportStatus(id);
              break;
            case 1:
              this.$message.showSuccess('import_csv_finished_success');
              this.$refs.pagination.resetPagination();
              this.removeNormalImportingSubdomain();
              break;
            case 2:
              if (result.count) this.$refs.pagination.resetPagination();
              this.downloadCsv(result.errorData, result.filename);
              this.$message.showSuccess('import_csv_finished_fail', true);
              this.removeNormalImportingSubdomain();
              break;
            case 3:
              this.$message.showError('import_csv_fail');
              this.$refs.pagination.resetPagination();
              this.removeNormalImportingSubdomain();
              break;
            case 4:
              this.$message.showError('import_csv_duplicate_fail');
              this.$refs.pagination.resetPagination();
              this.removeNormalImportingSubdomain();
              break;
            case 5:
              this.$message.showError('import_csv_fail');
              this.$refs.pagination.resetPagination();
              this.removeNormalImportingSubdomain();
              break;
            default:
              break;
          }
        } else {
          this.removeNormalImportingSubdomain();
        }
      }, 10000);
    },
    handleShowModal() {
      this.form = {
        amount: null,
      }
      this.showModal('customerCpmModal')
    },
    checkExportStatus(id) {
      this.statusTimer = setTimeout(async () => {
        const response = await this.$store.dispatch('common/getStatusExportCSV', id);
        if (response) {
          switch(response.status) {
            case StatusConstants.exportCsvStatus.waiting:
              this.checkExportStatus(id);
              break;
            case StatusConstants.exportCsvStatus.success:
              await this.$store.dispatch('customer/getCustomerTotal', { ...this.customParams, type: StatusConstants.customerType.all.value });
              this.$message.showMessageSuccess(`${this.form.amount}件のCPM IDを発行しました。`);
              this.downloadUrlCsv(response.url);
              this.$refs.pagination.resetPagination();
              this.isCpmDisabled = false;
              break;
            default:
              break;
          }
        } else {
          this.removeIdRequestExport();
        }
      }, 5000);
    },
    async submitCustomerCpm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'customerCpm');
      } else {
        this.isCpmDisabled = true;
        const result = await this.$store.dispatch('customer/createCustomerCpm', {
          amount: this.form.amount,
          subdomain: this.subdomain,
        });
        if (result) {
          this.closeModal('customerCpmModal');
          this.$message.showMessageSuccess(`${this.form.amount}件のCPM ID発行を開始しました。`);
          this.checkExportStatus(result.id);
        }
      }
    },
    handleOpenModal(modal) {
      if (this.selectedUsers?.allFlag || this.selectedUsers?.ids?.length > 0) {
        this.modalType = modal;
        this.pushModal = true;
      } else {
        this.$message.showError('noSelectedCustomer');
      }
    },
    async handleVerifyModal(type, data) {
      const isNotificationModal = type === this.notificationModal;
      const isCouponModal = type === this.couponModal;
      const action = isNotificationModal ? 'pushNotice' : isCouponModal ? 'pushCoupons' : 'pushNews';
      const res = await this.$store.dispatch(`customer/${action}`, {
        ...data,
        target: this.selectedUsers?.ids,
        allFlag: this.selectedUsers.allFlag,
        searchCondition: {
          ...this.initialSearchConditions,
          ...this.searchForm,
          ...JSON.parse(sessionStorage.getItem('searchConditions')),
        },
      });
      if (res) {
        this.$message.showMessageSuccess(res?.data?.message);
        this.pushModal = false;
        this.$refs.pagination.resetPagination();
      }
    },
    addSearch() {
      this.isSearchFormOpened = !this.isSearchFormOpened;
    },
  },
  beforeDestroy() {
    clearTimeout(this.statusNormalTimer);
  }
};
</script>
